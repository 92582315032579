<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>My Claims</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-2"
          v-model="search"
          label="Search Claims"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span style="width: 110px" v-bind="attrs" v-on="on">
            <FiscalYearField
              :numberOfFutureYears="0"
              :numberOfPastYears="selectedParticipant.participantType.participantTypeKey == 100 ? yearsUntil2020() : 1"
              @inputObj="
                selectedFiscalYear = $event;
                getData();
              "
              :client="selectedClient"
              flat
              solo-inverted
              hide-details
              class="mr-2"
              use-store
            ></FiscalYearField>
          </span> </template
        >Fiscal Year</v-tooltip
      >
      <v-btn class="mr-2" @click="getData" color="primary">Search</v-btn>
      <v-btn color="success" :to="{ name: 'cprClaims' }" v-if="$privilege.hasPrivilege('CLAIM_CREATE')"
        >Start a new Claim</v-btn
      >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            fab
            small
            @click="$router.push({ name: 'allClaimTypesReporting' })"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-file-document-outline</v-icon>
          </v-btn>
        </template>
        <span>Generate Report</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text v-if="filters">
      <v-tabs v-model="tab">
        <v-tab>
          <v-badge color="primary" :content="approvedTotal" v-if="approvedTotal > 0">
            Approved
          </v-badge>
          <span v-else>Approved</span>
        </v-tab>
        <v-tab>
          <v-badge color="primary" :content="pendingTotal" v-if="pendingTotal > 0">
            Pending
          </v-badge>
          <span v-else>Pending</span>
        </v-tab>
        <v-tab>
          <v-badge color="primary" :content="incompletedTotal" v-if="incompletedTotal > 0">
            Incomplete
          </v-badge>
          <span v-else>Incomplete</span>
        </v-tab>
        <v-tab>
          <v-badge color="primary" :content="rejectedTotal" v-if="rejectedTotal > 0">
            Rejected
          </v-badge>
          <span v-else>Rejected</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item eager>
          <ClaimApprovedTable :filters="filters" @totalElements="approvedTotal = $event" />
        </v-tab-item>
        <v-tab-item eager>
          <ClaimPendingTable :filters="filters" @totalElements="pendingTotal = $event" />
        </v-tab-item>
        <v-tab-item eager>
          <ClaimIncompleteTable :filters="filters" @totalElements="incompletedTotal = $event" />
        </v-tab-item>
        <v-tab-item eager>
          <ClaimRejectedTable :filters="filters" @totalElements="rejectedTotal = $event" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import ClaimRejectedTable from "../../gapp-components/components/tables/ClaimRejectedTable.vue";
import ClaimIncompleteTable from "../../gapp-components/components/tables/ClaimIncompleteTable.vue";
import ClaimPendingTable from "../../gapp-components/components/tables/ClaimPendingTable.vue";
import ClaimApprovedTable from "../../gapp-components/components/tables/ClaimApprovedTable.vue";
import FiscalYearField from "../../gapp-components/components/fields/FiscalYearField.vue";
import { mapGetters } from "vuex";

export default {
  components: { FiscalYearField, ClaimApprovedTable, ClaimPendingTable, ClaimIncompleteTable, ClaimRejectedTable },
  name: "MyClaims",
  metaInfo: {
    title: "My Claims"
  },
  data() {
    return {
      loading: true,
      tab: 0,
      search: "",
      filters: undefined,
      pendingTotal: 0,
      approvedTotal: 0,
      incompletedTotal: 0,
      rejectedTotal: 0,
      options: {
        sortBy: ["createdDate"],
        sortDesc: [true],
        itemsPerPage: 10,
        page: 1
      },
      selectedFiscalYear: undefined
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    onClear() {
      this.search = "";
      this.getData();
    },
    onSubmit() {
      this.options.page = 0;
      this.getData();
    },
    getData() {
      let filters = {};
      filters.participant = { id: this.selectedParticipant.id };

      if (this.selectedParticipant.organization) {
        filters.organization = {
          id: this.selectedParticipant.organization.id
        };
      }

      if (this.selectedFiscalYear) {
        filters.fiscalYear = this.selectedFiscalYear.fiscalYear;
      }

      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }
      this.$set(this, "filters", filters);
    },
    yearsUntil2020() {
      let currentFiscalYear = this.$client.getCurrentFiscalYear(this.selectedClient).fiscalYear;
      return currentFiscalYear - 2020;
    }
  },
  mounted() {
    if (this.$route.query.tab) {
      this.tab = parseInt(this.$route.query.tab);
    } else {
      this.tab = 0;
    }
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      { text: "My Claims" }
    ]);
    this.getData();
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"])
  }
};
</script>
