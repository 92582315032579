var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("My Claims")]),_c('v-spacer'),_c('v-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"label":"Search Claims","flat":"","solo-inverted":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},on:{"click:clear":_vm.onClear},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"width":"110px"}},'span',attrs,false),on),[_c('FiscalYearField',{staticClass:"mr-2",attrs:{"numberOfFutureYears":0,"numberOfPastYears":_vm.selectedParticipant.participantType.participantTypeKey == 100 ? _vm.yearsUntil2020() : 1,"client":_vm.selectedClient,"flat":"","solo-inverted":"","hide-details":"","use-store":""},on:{"inputObj":function($event){_vm.selectedFiscalYear = $event;
              _vm.getData();}}})],1)]}}])},[_vm._v("Fiscal Year")]),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.getData}},[_vm._v("Search")]),(_vm.$privilege.hasPrivilege('CLAIM_CREATE'))?_c('v-btn',{attrs:{"color":"success","to":{ name: 'cprClaims' }}},[_vm._v("Start a new Claim")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.$router.push({ name: 'allClaimTypesReporting' })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-document-outline")])],1)]}}])},[_c('span',[_vm._v("Generate Report")])])],1),(_vm.filters)?_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[(_vm.approvedTotal > 0)?_c('v-badge',{attrs:{"color":"primary","content":_vm.approvedTotal}},[_vm._v(" Approved ")]):_c('span',[_vm._v("Approved")])],1),_c('v-tab',[(_vm.pendingTotal > 0)?_c('v-badge',{attrs:{"color":"primary","content":_vm.pendingTotal}},[_vm._v(" Pending ")]):_c('span',[_vm._v("Pending")])],1),_c('v-tab',[(_vm.incompletedTotal > 0)?_c('v-badge',{attrs:{"color":"primary","content":_vm.incompletedTotal}},[_vm._v(" Incomplete ")]):_c('span',[_vm._v("Incomplete")])],1),_c('v-tab',[(_vm.rejectedTotal > 0)?_c('v-badge',{attrs:{"color":"primary","content":_vm.rejectedTotal}},[_vm._v(" Rejected ")]):_c('span',[_vm._v("Rejected")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"eager":""}},[_c('ClaimApprovedTable',{attrs:{"filters":_vm.filters},on:{"totalElements":function($event){_vm.approvedTotal = $event}}})],1),_c('v-tab-item',{attrs:{"eager":""}},[_c('ClaimPendingTable',{attrs:{"filters":_vm.filters},on:{"totalElements":function($event){_vm.pendingTotal = $event}}})],1),_c('v-tab-item',{attrs:{"eager":""}},[_c('ClaimIncompleteTable',{attrs:{"filters":_vm.filters},on:{"totalElements":function($event){_vm.incompletedTotal = $event}}})],1),_c('v-tab-item',{attrs:{"eager":""}},[_c('ClaimRejectedTable',{attrs:{"filters":_vm.filters},on:{"totalElements":function($event){_vm.rejectedTotal = $event}}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }